import React from "react"
import { Helmet } from 'react-helmet'

// Components
import BannerArrow from '../../global/bannerArrow'
import ContactStrip from '../../global/contactStrip'
import Testimonials from '../../global/testimonials'

// images
import logo from '../../images/case-studies/animal-health-trust/aht-logo.png'
import banner from '../../images/case-studies/animal-health-trust/Roll-up-banner.jpg'
import giftPack from '../../images/case-studies/animal-health-trust/legacy-gift-pack.jpg'
import van from '../../images/case-studies/animal-health-trust/animal-health-trust-van.jpg'
import horse from '../../images/case-studies/animal-health-trust/horse.jpg'
import folder from '../../images/case-studies/animal-health-trust/folder.jpg'

function AnimalHealthTrust() {
    const appConfig = window.globalConfig
    const testimonials = [
        {
            content: <>
                SuperHuman provided a first class service and
                looked holistically at all of our needs as a charity
                brand struggling to stand out from the crowd. I
                wouldn’t hesitate to recommend to others
            </>,
            cite: <>Director of Fundraising and Marketing - Animal Health Trust</>
        }
    ]

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Case Studies - Animal Health Trust</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">

                <div class="banner full animal-health-trust">
                    <img src={logo} alt="Animal Health Trust" />
                    <BannerArrow />
                </div>

                <section>
                    <div className="container">
                        <p className="fs-2-5 width-p-80 block-center">
                            The Animal Health Trust is an independent charity employing over
                            200 scientists, vets and support workers. They recognised an
                            opportunity to change their dated brand into something much
                            more creative, into a brand that would encourage investment into
                            this worthy charity.
                        </p>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={banner} className="width-p-100" alt="Animal Health Trust - Roll up banner" />
                </section>

                <section className="padd-0">
                    <img src={giftPack} className="width-p-100" alt="Animal Health Trust - Legacy Gift Pack" />
                </section>

                <section className="bg turquoise">
                    <div className="container">
                        <h2>We delivered</h2>
                        <div className="grid cols3">
                            <div><p className="fs-2 width-p-70">A vibrant and innovative brand refresh</p></div>
                            <div><p className="fs-2 width-p-80">Branded Marketing collateral to catch the eye</p></div>
                            <div><p className="fs-2 width-p-90">Cut through at events with signage and advertising</p></div>
                        </div>
                    </div>
                </section>

                <section className="section-text-image">
                    <div className="row">
                        <div className="image">
                            <img src={van} className="width-p-90 block-center" alt="Animal Health Trust - Van" />
                        </div>
                        <div className="text bg fur">
                            <p className="fs-2-5 padd-b-50 width-p-80 block-center">
                                We helped AHT to reimagine their brand in
                                reach, plus new areas of investment, and a
                                platform to build their reputation as one of the
                                country’s leading (although relatively unknown)
                                animal charities.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={horse} className="width-p-100" alt="Animal Health Trust - Horse" />
                </section>

                <section className="padd-0">
                    <img src={folder} className="width-p-100" alt="Animal Health Trust - Folder" />
                </section>

                <Testimonials data={testimonials} />

                <section className="prev-next-project">
                    <div className="row">
                        <a href="/case-studies/champions-club" className="item prev champions">
                            <span class="chevron left"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Previous Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Champions Club</strong></p>
                            </div>
                        </a>
                        <a href="/case-studies/amdocs" className="item next amdocs">
                            <span class="chevron right"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Next Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Amdocs</strong></p>
                            </div>
                        </a>
                    </div>
                </section>

                <ContactStrip />
            </div>
        </>
    )
}

export default AnimalHealthTrust