import React, { useRef, useState, useEffect } from "react"

function AnimateElemement(props) {
    const { animation } = props
    let elementRef = useRef(0)
    const [isElementInViewport, setIsElementInViewport] = useState(false);
    const animationClassName = isElementInViewport ? 'show' : '';
    const className = `animate ${animation} ${animationClassName} ${props.className ? props.className : ""}`

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
                setIsElementInViewport(true);
            }
            // else {
            //     setIsElementInViewport(false);
            // }
          });
        });
    
        if (elementRef.current) {
          observer.observe(elementRef.current);
        }
    
        return () => {
          if (elementRef.current) {
            observer.unobserve(elementRef.current);
          }
        };
      }, [elementRef]);
    
    return (
        <>
            {/* Render the children passed to AnimateElement */}
            {
                props.children &&
                React.cloneElement(
                    props.children, 
                    { 
                        className: `${className ? className : "" }`,
                        ref: elementRef
                    }
                )
            }
        </>
    )
}

export default AnimateElemement