import React from "react"
import { Helmet } from 'react-helmet'
import AnimateElemement from '../global/animateElement'

// Components
import BannerArrow from '../global/bannerArrow'
import ContactStrip from '../global/contactStrip'
import Testimonials from '../global/testimonials'

// images
import logo from '../images/superhuman-rh-logo.png'
import costIcon from '../images/icons/cost-icon.png'
import scalableIcon from '../images/icons/scalable-icon.png'
import personalIcon from '../images/icons/personal-icon.png'
import pedigreeIcon from '../images/icons/pedigree-icon.png'

function Discover() {
    const appConfig = window.globalConfig
    const testimonials = [
        {
            content: <>
                SuperHuman have worked for a number of years
                for Bridgestone Motorsport, both in Formula One
                and MotoGP, building a solid and trusting
                relationship along the way.
            </>,
            cite: <>Press Officer - Bridgestone Motorsport</>
        },
        {
            content: <>
                The team at SuperHuman surpassed my expectations, 
                delivering exceptional creative, on time and on budget.<br />
                Would highly recommend!
            </>,
            cite: <>CEO - Hedgehog Insurance</>
        },
        {
            content: <>
                SuperHuman provided a first class service and looked 
                holistically at all of our needs as a charity brand 
                struggling to stand out from the crowd. I wouldn’t 
                hesitate to recommend to others.
            </>,
            cite: <>Director of Fundraising and Marketing - Animal Health Trust</>
        }
    ]

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Discover</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">

                <div class="banner full discover">
                    <AnimateElemement animation="fade" className="text-center">
                        <h1>Discover</h1>
                    </AnimateElemement>
                    
                    <BannerArrow />
                </div>

                <section className="bg purple">
                    <div className="container">
                        <p className="fs-3-5 width-p-90">
                            At SuperHuman, we believe that the heart of
                            marketing lies in authentic human connections. In a
                            world dominated by digital noise, we're here to bring a
                            refreshing perspective to your brand.
                        </p>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <h2 className="text-purple text-center">Why us?</h2>
                        <p className="lh-110">
                            Harnessing years of industry experience, we identified a gap in the market. We
                            have since been dedicated to filling it by providing not just creative excellence, but
                            also unparalleled efficiency and cost-effectiveness in every project we undertake.
                        </p>
                    </div>
                </section>

                <section className="bg grey">
                    <div className="container">
                        <ul className="icons">
                            <li>
                                <img src={costIcon} alt="Cost Effective" />
                                <h3 className="text-purple">Cost <br /> effective</h3>
                                <p className="text-purple lh-120">
                                    Reduced design costs over
                                    traditional office-based
                                    agencies.
                                </p>
                            </li>
                            <li>
                                <img src={scalableIcon} alt="Cost Effective" />
                                <h3>Scalable <br /> solutions</h3>
                                <p className="lh-120">
                                    We offer you only the
                                    services you need no-matter how big or small.
                                    Experience cutting edge
                                    design that suits your
                                    budget.
                                </p>
                            </li>
                            <li>
                                <img src={personalIcon} alt="Cost Effective" />
                                <h3 className="text-purple">Personal <br /> touch</h3>
                                <p className="text-purple lh-120">
                                    Direct access to our top
                                    talent cutting out middle
                                    management, and without
                                    getting lost in translation.
                                </p>
                            </li>
                            <li className="lh-120">
                                <img src={pedigreeIcon} alt="Cost Effective" />
                                <h3>Creative <br /> pedigree</h3>
                                <p>
                                    Our talented team have
                                    decades of agency
                                    experience working across
                                    all facets of design and
                                    marketing.
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>

                <section className="bg purple">
                    <div className="container">
                        <blockquote>
                            <p className="tt-upper fs-9 marg-b-20 marg-t-0">
                                People buy with <span className="text-green">emotion</span> and then
                                justify with <span className="text-green">logic</span>.
                            </p>
                            <cite className="fs-4">Zig Ziglar</cite>
                        </blockquote>
                    </div>
                </section>

                <section className="bg green-dark">
                    <div className="container">
                        <h2 className="text-center">Sector Experience</h2>
                        <p className="fs-5">
                            B2B tech <span className="text-green">Automotive</span> Charities<br />
                            <span className="text-green">Logistics</span> Property <span className="text-green">Pharma</span> Telecoms
                        </p>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <h2 className="text-center text-purple">Our Mission</h2>
                        <p className="fs-3-9 text-purple">
                            To create marketing strategies that resonate on a 
                            human level. We don’t just sell products or services; 
                            we tell stories with emotional connections through 
                            personal branding.
                        </p>
                    </div>
                </section>

                <section className="bg-russ">
                    <div className="container">
                        <p className="fs-2 width-p-45 text-white lh-125">
                            With over 25 years of expertise in the design
                            and marketing industry, I am now
                            wholeheartedly fulfilling a lifelong dream by
                            establishing my own boutique agency. 
                        </p>
                        <p className="fs-2 width-p-45 text-white lh-125">
                            My goal is to infuse creative flair and deliver
                            value directly to you, regardless of the scale
                            of your project. Additionally, consider booking
                            me as your in-house creative consultant.
                        </p>
                        <cite>
                            <p className="fs-1-5 text-green marg-b-0"><strong>Russell Horton</strong></p>
                            <p className="fs-1-5 text-green marg-t-0 marg-b-70">Creative Director/Founder</p>
                        </cite>
                        <img src={logo} alt={appConfig.siteName} />
                    </div>
                </section>

                <Testimonials data={testimonials} />

                <ContactStrip />
            </div>
        </>
    )
}

export default Discover