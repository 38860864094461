import React from "react"
import { Helmet } from 'react-helmet'

// Components
import BannerArrow from '../../global/bannerArrow'
import ContactStrip from '../../global/contactStrip'
import Testimonials from '../../global/testimonials'

// images
import logo from '../../images/case-studies/bridgestone-motorsport/logo.png'
import motoGp from '../../images/case-studies/bridgestone-motorsport/moto-gp-media-information.jpg'
import newsRelease from '../../images/case-studies/bridgestone-motorsport/news-release.jpg'
import holdingPoster from '../../images/case-studies/bridgestone-motorsport/holding-poster.jpg'
import flags from '../../images/case-studies/bridgestone-motorsport/100wins_team05.jpg'
import victoriesVan from '../../images/case-studies/bridgestone-motorsport/354.jpg'
import team from '../../images/case-studies/bridgestone-motorsport/bridgestone-team.jpg'
import motorbike from '../../images/case-studies/bridgestone-motorsport/motorbike.jpg'

function Bridgestone() {
    const appConfig = window.globalConfig

    const testimonials = [
        {
            content: <>
                SuperHuman have worked for a number of years
                for Bridgestone Motorsport, both in Formula One
                and MotoGP, building a solid and trusting
                relationship along the way.
            </>,
            cite: <>Press Officer - Bridgestone Motorsport</>
        }
    ]

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Case Studies - Bridgestone Motorsport</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">

                <div class="banner full bridgestone">
                    <img src={logo} alt="Bridgestone Motorsport Logo" />
                    <BannerArrow />
                </div>

                <section>
                    <div className="container">
                        <p className="fs-2-5 width-p-80 block-center">
                            Bridgestone Motorsport’s MotoGP participation provided a global
                            stage for its technology and expertise. We created a vibrant and
                            contemporary design that reflected the speed, excitement and
                            passion of the premier motorcycle racing World Championship
                            across all of their global press and corporate communications.
                        </p>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={motoGp} className="width-p-100" alt="Moto GP - Media Information" />
                </section>

                <section className="bg red">
                    <div className="container">
                        <h2>We delivered</h2>
                        <div className="grid cols3">
                            <div><p className="fs-2 width-p-70">Corporate press packs and digital assets</p></div>
                            <div><p className="fs-2 width-p-80">Promotional paddock and motorhome graphics that grabbed attention</p></div>
                            <div><p className="fs-2 width-p-90">Advertising posters, E-cards, and promotional merchandise</p></div>
                        </div>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={newsRelease} className="width-p-100" alt="Moto GP - News Release" />
                </section>

                <section>
                    <img src={holdingPoster} className="width-p-50 block-center" alt="Holding Poster" />
                </section>

                <section className="padd-0">
                    <img src={flags} className="width-p-100" alt="Moto GP - Media Information" />
                </section>

                <section className="grid cols2">
                    <div>
                        <img src={victoriesVan} className="width-p-100" alt="Bridgestone" />
                    </div>
                    <div>
                        <img src={team} className="width-p-100" alt="Bridgestone Motorsport Team" />
                    </div>
                </section>

                <Testimonials data={testimonials} />

                <section className="padd-0">
                    <img src={motorbike} className="width-p-100" alt="Yamaha Motorbike" />
                </section>

                <section className="prev-next-project">
                    <div className="row">
                        <a href="/case-studies/world-wildlife-fund" className="item prev wwf">
                            <span class="chevron left"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Previous Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>World Wildlife Fund</strong></p>
                            </div>
                        </a>
                        <a href="/case-studies/paddock-link" className="item next plink">
                            <span class="chevron right"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Next Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Paddock Link</strong></p>
                            </div>
                        </a>
                    </div>
                </section>

                <ContactStrip />
            </div>
        </>
    )
}

export default Bridgestone