import React from "react"
import { Helmet } from 'react-helmet'
import laptop from '../../images/general/amdocs/laptop.jpg'
import facts from '../../images/general/amdocs/facts-so-far.jpg'
import ipads from '../../images/general/amdocs/amdocs-ipads.jpg'
import selfie from '../../images/general/amdocs/friends-selfie.jpg'

// Components
import BannerArrow from '../../global/bannerArrow'
import ContactStrip from '../../global/contactStrip'

function Amdocs() {
    const appConfig = window.globalConfig

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Case Studies - Amdocs</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">

                <div class="banner full amdocs">
                    <div className="intro-content">
                        <h1>amdocs</h1>
                        <p><strong>embrace challenge</strong></p>
                        <p><strong>e<sup>x</sup>perience success</strong></p>
                    </div>
                    <BannerArrow />
                </div>

                <section>
                    <div className="container">
                        <p className="max-width-1150 block-center">
                            Amdocs, a leading software and services provider offering
                            innovative solutions and intelligent operations for their customers,
                            recognised that their brand didn’t portray their innovative flair, so
                            they approached us to reimagine their brand story.
                        </p>
                    </div>
                </section>

                <section className="section-2-1">
                    <div>
                        <div>
                            <div className="bg grey">
                                <img src={laptop} alt="Laptop" className="width-p-80" />
                            </div>
                            <div className="hammock">&nbsp;</div>
                        </div>
                        <div className="facts">
                            <img src={facts} alt="Facts so far" />
                        </div>
                    </div>
                </section>

                <section className="bg orange">
                    <div className="container">
                        <h2>We delivered</h2>
                        <div className="grid cols3">
                            <div>
                                <p className="fs-2 width-p-80">
                                    A vibrant and innovative
                                    brand suite, executed
                                    across all of the Amdocs
                                    ecosystem
                                </p>
                            </div>
                            <div>
                                <p className="fs-2 width-p-80">
                                    Advertising that cut
                                    through the clouded digital
                                    transformation market with
                                    brand messaging and
                                    imagery
                                </p>
                            </div>
                            <div>
                                <p className="fs-2 width-p-80">
                                    A programme of targeted
                                    brand imagery and content
                                    to inform their customers
                                    across multiple social and
                                    digital channels
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={ipads} className="width-p-100" alt="Amdocs iPads" />
                </section>
                
                <section className="section-text-image">
                    <div className="row">
                        <div className="text bg grey-blue">
                            <p className="fs-2-5 marg-0 width-p-60 block-center">
                                Taking the opportunity for
                                change, Amdocs reimaged their
                                brand and reached their
                                customers in a new and
                                exciting way that delivered not
                                only new reach, but continued
                                business growth, and a platform
                                to build their reputation as an
                                industry leader.
                            </p>
                        </div>
                        <div className="image x-phone bg-image">
                            &nbsp;
                        </div>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={selfie} className="width-p-100" alt="Amdocs" />
                </section>
                
                <section className="prev-next-project">
                    <div className="row">
                        <a href="/case-studies/animal-health-trust" className="item prev aht">
                            <span class="chevron left"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Previous Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Animal Health Trust</strong></p>
                            </div>
                        </a>
                        <a href="/case-studies/henley-festival" className="item next henley-festival">
                            <span class="chevron right"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Next Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Henley Festival</strong></p>
                            </div>
                        </a>
                    </div>
                </section>

                <ContactStrip />
            </div>
        </>
    )
}

export default Amdocs