import React from "react"
import { Helmet } from 'react-helmet'

// Components
import BannerArrow from '../../global/bannerArrow'
import ContactStrip from '../../global/contactStrip'
import Testimonials from '../../global/testimonials'

// images
import logo from '../../images/case-studies/hedgehog-insurance/hedgehog-logo.png'
import spikes from '../../images/case-studies/hedgehog-insurance/hedgehog-pattern.png'
import laptop from '../../images/case-studies/hedgehog-insurance/website-home-angle.jpg'
import docs from '../../images/case-studies/hedgehog-insurance/hedgehog-policy-docs.jpg'
import phone from '../../images/case-studies/hedgehog-insurance/iphone_hedgehog.jpg'
import email from '../../images/case-studies/hedgehog-insurance/email-mockup.jpg'
import car from '../../images/case-studies/hedgehog-insurance/car-love.jpg'

function Hedgehog() {
    const appConfig = window.globalConfig

    const testimonials = [
        {
            content: <>
                The team at SuperHuman surpassed my
                expectations, delivering exceptional creative, on
                time and on budget.<br />
                Would highly recommend!
            </>,
            cite: <>CEO - Hedgehog Insurance</>
        }
    ]

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Case Studies - Hedgehog Insurance</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">

                <div class="banner full hedgehog">
                    <img src={logo} alt="Hedgehog Insurance Logo" />
                    <BannerArrow />
                </div>

                <section>
                    <div className="container">
                        <p className="fs-2-5 width-p-80 block-center">
                            Hedgehog car insurance’s brand felt prickly. There was an
                            opportunity to build on Hedgehog’s unique virtual presence.
                            We enhanced their existing brand to build a stronger presence
                            across the insurance community.
                        </p>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={spikes} className="width-p-100" alt="Hedgehog Insurance" />
                </section>

                <section className="padd-0">
                    <img src={laptop} className="width-p-100" alt="Hedgehog Insurance Laptop" />
                </section>

                <section>
                    <div className="container">
                        <h2>We delivered</h2>
                        <div className="grid cols3">
                            <div><p className="fs-2 width-p-70">An enhanced brand story</p></div>
                            <div><p className="fs-2 width-p-80">Messaging that brings the brand to life</p></div>
                            <div><p className="fs-2 width-p-90">Branded communications that cut through</p></div>
                        </div>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={docs} className="width-p-100" alt="Hedgehog Insurance - Breakdown policy wording" />
                </section>

                <section className="padd-0">
                    <img src={phone} className="width-p-100" alt="Hedgehog Insurance - Phone" />
                </section>

                <Testimonials data={testimonials} />

                <section className="padd-0">
                    <img src={email} className="width-p-100" alt="Hedgehog Insurance - Email Mockups" />
                </section>

                <section className="padd-0">
                    <img src={car} className="width-p-100" alt="Hedgehog Insurance" />
                </section>

                <section className="prev-next-project">
                    <div className="row">
                        <a href="/case-studies/paddock-link" className="item prev plink">
                            <span class="chevron left"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Previous Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Paddock Link</strong></p>
                            </div>
                        </a>
                        <a href="/case-studies/champions-club" className="item next champions">
                            <span class="chevron right"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Next Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Champions Club</strong></p>
                            </div>
                        </a>
                    </div>
                </section>

                <ContactStrip />
            </div>
        </>
    )
}

export default Hedgehog