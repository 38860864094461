import React from "react"

function BannerArrow() {
    
    function scrollToContent() {
        let screenHeight = window.innerHeight + 1
        window.scrollTo({top: screenHeight, behavior: 'smooth'});
    }

    return (
        <div className="chevron bottom" onClick={() => scrollToContent()}></div>
    )
}

export default BannerArrow