import React from "react"

import { Carousel } from 'react-responsive-carousel'

function ContactStrip({data}) {
    let show = data.length > 0
    return (
        show &&
        <section className="bg green d-block">
            <Carousel
                showStatus={false}
                showThumbs={false}
                autoPlay={true}
                interval={3000}
                infiniteLoop={true}
                showArrows={false}
                showIndicators={data.length > 1 ? true : false}
            >
                {
                    data.map((item, i) => {
                        return (
                            <blockquote className="testimonial" key={i}>
                                <div className="container">
                                    <p className="fs-4"> { item.content } </p>
                                    <cite className="fs-2">{ item.cite }</cite>
                                </div>
                            </blockquote>
                        )
                    })
                }
            </Carousel>
        </section>
    )
}

export default ContactStrip