import React from "react"
import { Helmet } from 'react-helmet'

// Components
import BannerArrow from '../../global/bannerArrow'
import ContactStrip from '../../global/contactStrip'

// Images
import logo from '../../images/case-studies/wwf/wwf-logo.png'
import logoBlack from '../../images/case-studies/wwf/wwf-logo-black.png'
import gorilla from '../../images/case-studies/wwf/WWF-visual.jpg'
import quote from '../../images/case-studies/wwf/wwf-quote.jpg'
import phone from '../../images/case-studies/wwf/phone.jpg'
import phoneMobile from '../../images/case-studies/wwf/phone-mobile.jpg'
import brochure from '../../images/case-studies/wwf/wwf-brochure.jpg'
import brochureMobile from '../../images/case-studies/wwf/wwf-brochure-mobile.jpg'

// images

function wwf() {
    const appConfig = window.globalConfig

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Case Studies - World Wildlife Fund</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">

                <div class="banner full wwf">
                    <img src={logo} alt="WWF Logo" />
                    <BannerArrow />
                </div>

                <section className="bg black">
                    <div className="container">
                        <p className="fs-2-5 width-p-80 block-center">
                            The World Wildlife Fund is one of the most recognised wildlife
                            brands in the world. Seeing an opportunity to boost their social
                            presence, WWF wanted to create a new look and feel for their
                            social experiences.
                        </p>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={gorilla} className="width-p-100" alt="WWF Logo" />
                </section>

                <section className="table width-p-100 padd-0">
                    <div className="row">
                        <div className="cell width-p-50 bg eggshell-blue text-center valign-middle mq-900-padd desktop-only">
                            <img src={logoBlack} alt="WWF Logo" />
                        </div>
                        <div className="cell width-p-50">
                            <img src={quote} className="width-p-100" alt="WWF Logo" />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <h2>We delivered</h2>
                        <div className="grid cols3">
                            <div><p className="fs-2 width-p-70">An extension of a worldrenowned brand</p></div>
                            <div><p className="fs-2 width-p-80">Increased attraction and brand retention</p></div>
                            <div><p className="fs-2 width-p-90">Greater social reach and engagement</p></div>
                        </div>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={phone} className="width-p-100 desktop-only" alt="WWF Logo" />
                    <img src={phoneMobile} className="width-p-100 mobile-only" alt="WWF Logo" />
                </section>

                <section className="bg black">
                    <div className="container">
                        <p className="fs-2-5">
                            The results were amazing design delivered across multiple digital, print and social
                            touchpoints allowing WWF to have a far greater social reach and helping to bring
                            the WWF brand to a new audience.
                        </p>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={brochure} className="width-p-100 desktop-only" alt="WWF phone" />
                    <img src={brochureMobile} className="width-p-100 mobile-only" alt="WWF phone" />
                </section>
                
                <section className="prev-next-project">
                    <div className="row">
                        <a href="/case-studies/henley-festival" className="item prev henley-festival">
                            <span class="chevron left"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Previous Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Henley Festival</strong></p>
                            </div>
                        </a>
                        <a href="/case-studies/bridgestone-motorsport" className="item next bridgestone">
                            <span class="chevron right"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Next Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Bridgestone Motorsport</strong></p>
                            </div>
                        </a>
                    </div>
                </section>

                <ContactStrip />
            </div>
        </>    
    )
}
export default wwf
