import React from "react"
import { Helmet } from 'react-helmet'

// Components
import BannerArrow from '../../global/bannerArrow'
import ContactStrip from '../../global/contactStrip'

// images
import henleyBoat from '../../images/case-studies/henley-festival/Henley-boat.jpg'
import alfieBoe from '../../images/case-studies/henley-festival/alfie-boe.jpg'
import programCover from '../../images/case-studies/henley-festival/Programme-cover.jpg'
import programCoverMobile from '../../images/case-studies/henley-festival/Programme-cover-mobile.jpg'
import bigDuck from '../../images/case-studies/henley-festival/Big-Duck.jpg'
import programMan from '../../images/case-studies/henley-festival/Perfect_Binding_Brochure_Mockup_5.jpg'
import programManMobile from '../../images/case-studies/henley-festival/Perfect_Binding_Brochure_Mockup_5-mobile.jpg'
import fish from '../../images/case-studies/henley-festival/fish.jpg'
import club from '../../images/case-studies/henley-festival/club.jpg'
import concert from '../../images/case-studies/henley-festival/concert.jpg'


function HenleyFestival() {
    const appConfig = window.globalConfig

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Case Studies - Henley Festival</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">

                <div class="banner full henley-festival">
                    <h1 class="text-center">
                        <span>Henley</span> 
                        <span>Festival</span>
                    </h1>
                    <BannerArrow />
                </div>

                <section className="bg chartreuse">
                    <div className="container">
                        <p className="width-p-80 block-center">
                            The Henley Festival is one of the most exclusive black-tie events
                            of the English summer. Attracting big business, celebrity attention,
                            and the socialites of the business world presents an opportunity
                            for Henley Festival to reinvent itself every year.
                        </p>
                    </div>
                </section>

                <section className="grid cols2">
                    <div>
                        <img src={henleyBoat} className="width-p-100" alt="henleyBoat" />
                    </div>
                    <div>
                        <img src={alfieBoe} className="width-p-100" alt="alfieBoe" />
                    </div>
                </section>

                <section className="padd-0">
                    <img src={programCover} className="width-p-100 desktop-only" alt="programCover" />
                    <img src={programCoverMobile} className="width-p-100 mobile-only" alt="programCover" />
                </section>

                <section>
                    <div className="container">
                        <h2>We delivered</h2>
                        <div className="grid cols3">
                            <div><p className="fs-2 width-p-70">A fresh new look for its 37th year</p></div>
                            <div><p className="fs-2 width-p-80">Design with impact to draw in a prestigious crowd</p></div>
                            <div><p className="fs-2 width-p-90">A five-day programme that begged to be kept as a memento of the experience</p></div>
                        </div>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={bigDuck} className="width-p-100" alt="bigDuck" />
                </section>

                <section className="padd-0">
                        <div> 
                            <img src={programMan} className="width-p-100 desktop-only" alt="programMan" />
                            <img src={programManMobile} className="width-p-100 mobile-only" alt="programMan" />
                        </div>
                </section>

                <section className="grid cols2">
                    <div>
                        <img src={fish} className="width-p-100" alt="fish" />
                    </div>
                    <div>
                        <img src={club} className="width-p-100" alt="club" />
                    </div>
                </section>
                
                <section className="bg black">
                    <div className="container">
                        <p>
                            Like any festival the to-do list for organisers is massive, we were able to take two
                            of the biggest burdens away - design and marketing. The result was a breathtaking design, delivered hassle free.
                        </p>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={concert} className="width-p-100" alt="concert" />
                </section>

                <section className="prev-next-project">
                    <div className="row">
                        <a href="/case-studies/amdocs" className="item prev amdocs">
                            <span class="chevron left"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Previous Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Amdocs</strong></p>
                            </div>
                        </a>
                        <a href="/case-studies/world-wildlife-fund" className="item next wwf">
                            <span class="chevron right"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Next Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>World Wildlife Fund</strong></p>
                            </div>
                        </a>
                    </div>
                </section>

                <ContactStrip />
            </div>
        </>
    )
}

export default HenleyFestival