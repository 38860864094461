import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import emailjs from '@emailjs/browser'
import ReCAPTCHA from 'react-google-recaptcha'
import AnimateElemement from '../global/animateElement'

// Components
import BannerArrow from '../global/bannerArrow'

// images
import desk from '../images/general/desk.jpg'

function Discover() {
    const appConfig = window.globalConfig
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [recaptchaToken, setRecaptchaToken] = useState('')
    const [formErrorMessage, setFormErrorMessage] = useState('')
    const [formSuccessMessage, setFormSuccessMessage] = useState('')

    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        message: ''
    })
    const [formErrorValues, setFormErrorValues] = useState({
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
        recaptcha: false
    })

    function handleRecaptchaChange(token) {
        setRecaptchaToken(token);
      }
    
    function handleFormValues(e) {
        const {name, value} = e.target
        if(name === "phone" && isNaN(value)) {
            return false
        }
        setFormValues({...formValues, [name]: value})
    }

    useEffect(() => {
        validateForm()
    }, [formValues])

    function validateEmail(email) {
        // Regular expression for email validation
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    }

    useEffect(() => {
        validateForm()
    }, [recaptchaToken])

    function validateForm(fromSubmit) {
        let firstName = false
        let lastName = false
        let phone = false
        let email = false
        let recaptcha = false
        
        if(formSubmitted || fromSubmit) {
            if(formValues.firstName === "") firstName = true
            if(formValues.lastName === "") lastName = true
            if(formValues.phone === "") phone = true
            if(formValues.email === "") email = true
            else if(!validateEmail(formValues.email)) email = 'invalid'
            if(!recaptchaToken) recaptcha = true

            let message = ''
            if(firstName ||
                lastName ||
                phone ||
                email === true
            ) {
                message = 'Please fill in the form'
            }
            else if(email === "invalid") {
                message = 'Please supply a correct email'
            }
            else if(!recaptchaToken) {
                message = 'Please verify you are not a robot'
            }

            setFormErrorValues({
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                email: email,
                recaptcha: recaptcha
            })

            setFormErrorMessage(message)

            if(message === '') return true
            else return false
        }
    }

    const sendEmail = (e) => {
        setFormSubmitted(true)
        setIsLoading(true)
        e.preventDefault()

        if (validateForm(true)) {
            emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                setFormSuccessMessage("Thanks for contacting us, someone will be in touch soon.")
                setIsLoading(false)
            }, (error) => {
                setFormErrorMessage("There was an error submitting the form")
                setIsLoading(false)
                setFormSubmitted(false)
            });
        }
    }

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Contact</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">

                <div className="banner full contact">
                    <AnimateElemement animation="fade" className="text-center">
                        <h1>Contact</h1>
                    </AnimateElemement>
                    <BannerArrow />
                </div>

                <section className="bg green-dark">
                    <div className="container">
                        <p className="fs-3-8">
                            Ready to infuse your brand with a touch of humanity?
                        </p>
                        <p className="fs-3-8">
                            We’re thrilled to connect with you and explore the
                            extraordinary possibilities for your design and
                            marketing journey. 
                        </p>
                        <p className="fs-3-8 text-green"><strong>Let’s walk the walk together!</strong></p>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={desk} className="width-p-100" alt={appConfig.siteName} />
                </section>

                <section>
                    <div className="container">
                        <p className="fs-3-9">
                            Got a question? Let’s chat! Please contact us and
                            we will be happy to give you insight into everything
                            we do here at SuperHuman. 
                        </p>
                    </div>
                </section>

                <section className="contact bg green">
                    <div className="container">
                        <div className="details">
                            <h2 className="fs-3-5">Get in touch</h2>
                            <address>
                                <h2 className="fs-2 marg-b-0">SuperHuman Creative Limited</h2>
                                <p className="marg-t-0 fs-2">
                                    7 Bluebell Crescent<br />
                                    Woodley<br />
                                    Reading<br />
                                    Berkshire<br />
                                    RG54WP
                                </p>
                            </address>
                            <a href="tel:07917 625007" className="text-green-dark fs-2"><strong>07917 625007</strong></a>
                            <a href="mailto:hello@superhumancreative.co.uk" className="text-green-dark fs-2"><strong>hello@superhumancreative.co.uk</strong></a>
                        </div>
                        <div className="form">
                            <form onSubmit={sendEmail}>
                                <label htmlFor="txtFirstName">First Name</label>
                                <input
                                    id="txtFirstName"
                                    type="text"
                                    placeholder="Type your first name"
                                    value={formValues.firstName}
                                    onChange={(e) => handleFormValues(e)}
                                    disabled={formSuccessMessage}
                                    className={formErrorValues.firstName ? "error" : ""}
                                    name="firstName"
                                />
                                <label htmlFor="txtLastName">Last Name</label>
                                <input
                                    id="txtLastName"
                                    type="text"
                                    placeholder="Type your last name"
                                    value={formValues.lastName}
                                    onChange={(e) => handleFormValues(e)}
                                    disabled={formSuccessMessage}
                                    className={formErrorValues.lastName ? "error" : ""}
                                    name="lastName"
                                />
                                <label htmlFor="txtPhone">Phone</label>
                                <input
                                    id="txtPhone"
                                    type="text"
                                    placeholder="Type your phone number"
                                    value={formValues.phone}
                                    onChange={(e) => handleFormValues(e)}
                                    disabled={formSuccessMessage}
                                    className={formErrorValues.phone ? "error" : ""}
                                    name="phone"
                                />
                                <label htmlFor="txtEmail">Email</label>
                                <input
                                    id="txtEmail"
                                    type="text"
                                    placeholder="Type your email"
                                    value={formValues.email}
                                    onChange={(e) => handleFormValues(e)}
                                    disabled={formSuccessMessage}
                                    className={formErrorValues.email ? "error" : ""}
                                    name="email"
                                />
                                <label htmlFor="txtMessage">Message</label>
                                <textarea
                                    id="txtMessage"
                                    type="text"
                                    value={formValues.message}
                                    onChange={(e) => handleFormValues(e)}
                                    disabled={formSuccessMessage}
                                    name="message"
                                />
                                {
                                    !formSuccessMessage &&
                                    <ReCAPTCHA
                                        sitekey="6LfbJiMgAAAAAC4O889_vl6OgANvjWYRaWk7sS3l"
                                        onChange={handleRecaptchaChange}
                                        className="recaptcha"
                                    />
                                }
                                
                                {
                                    formErrorMessage !== "" &&
                                    <div className="error-message">{formErrorMessage}</div>
                                }
                                {
                                    formSuccessMessage &&
                                    <div className="success-message">{formSuccessMessage}</div>
                                }
                                {
                                    !formSuccessMessage &&
                                    <button className="btn green-dark">
                                        Submit
                                    </button>
                                }
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Discover