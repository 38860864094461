import React from "react"
import { Helmet } from 'react-helmet'

// Components
import BannerArrow from '../../global/bannerArrow'
import ContactStrip from '../../global/contactStrip'

// images
import logo from '../../images/case-studies/paddock-link/plink-logo.png'
import phone from '../../images/case-studies/paddock-link/Plink-mob.jpg'
import phoneMobile from '../../images/case-studies/paddock-link/Plink-mob-mobile.jpg'
import books from '../../images/case-studies/paddock-link/plink-books.jpg'
import f1logo from '../../images/case-studies/paddock-link/formula-1-seeklogo.png'
import cards from '../../images/case-studies/paddock-link/Plink-biz-cards.jpg'
import lanyard from '../../images/case-studies/paddock-link/plink-lanyard.jpg'
import blackBook from '../../images/case-studies/paddock-link/black-book.jpg'
import bookSpread from '../../images/case-studies/paddock-link/Book-Spread.jpg'
import f1Car from '../../images/case-studies/paddock-link/f1-car.jpg'
import cap from '../../images/case-studies/paddock-link/plink-cap.jpg'

function Plink() {
    const appConfig = window.globalConfig

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Case Studies - Paddock Link</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">

                <div class="banner full plink">
                    <img src={logo} alt="Paddock Link Logo" />
                    <BannerArrow />
                </div>

                <section className="bg orange-dark">
                    <div className="container">
                        <p className="fs-2-5 width-p-80 block-center">
                            In the fast-paced world of motorsport there was an opportunity for
                            a mobile app that aggregates all of the latest news and information
                            across the motorsport community. We were tasked with creating a
                            fresh brand for Paddock Link and designing a business link app that
                            connected the world of motorsport. We created the first Motorsport
                            community application, Plink!
                        </p>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={phone} className="width-p-100 desktop-only" alt="Plink phone" />
                    <img src={phoneMobile} className="width-p-100 mobile-only" alt="Plink phone" />
                </section>

                <section>
                    <div className="container">
                        <h2>We delivered</h2>
                        <div className="grid cols3">
                            <div><p className="fs-2 width-p-70">A disruptive cut-through brand</p></div>
                            <div><p className="fs-2 width-p-80">A platform to connect the motorsport community</p></div>
                            <div><p className="fs-2 width-p-90">Aggregate the latest news across F1, F2, F3, F4, and MotoGP categories</p></div>
                        </div>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={books} className="width-p-100" alt="Plink books" />
                </section>

                <section className="section-text-image">
                    <div className="row">
                        <div className="text bg orange-dark">
                            <p className="fs-2-5 marg-0 width-p-60 block-center">
                                Plink was the first motorsport
                                application designed for
                                professionals, providing a
                                platform for discussion. A view
                                of job opportunities and team
                                changes, plus a central point for
                                information and resources.
                            </p>
                        </div>
                        <div className="image mq-1200-padd">
                            <img src={f1logo} className="block-center mobile-w-40" alt="Plink books" />
                        </div>
                    </div>
                </section>

                <section className="grid cols2">
                    <div>
                        <img src={cards} className="width-p-100" alt="Plink Business Cards" />
                    </div>
                    <div>
                        <img src={lanyard} className="width-p-100" alt="Plink Lanyard" />
                    </div>
                </section>

                <section className="padd-0">
                    <img src={blackBook} className="width-p-100" alt="Paddock Link 2020 Black Book" />
                </section>

                <section className="padd-0">
                    <img src={bookSpread} className="width-p-100" alt="Paddock Link - Networking" />
                </section>

                <section className="grid cols2">
                    <div>
                        <img src={f1Car} className="width-p-100" alt="F1 Car" />
                    </div>
                    <div>
                        <img src={cap} className="width-p-100" alt="Paddock Link Cap" />
                    </div>
                </section>

                <section className="prev-next-project">
                    <div className="row">
                        <a href="/case-studies/bridgestone-motorsport" className="item prev bridgestone">
                            <span class="chevron left"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Previous Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Bridgestone Motorsport</strong></p>
                            </div>
                        </a>
                        <a href="/case-studies/hedgehog-insurance" className="item next hedgehog">
                            <span class="chevron right"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Next Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Hedgehog Insurance</strong></p>
                            </div>
                        </a>
                    </div>
                </section>

                <ContactStrip />
            </div>
        </>
    )
}

export default Plink