import React, { useEffect, useState } from "react"
import { Button } from 'react-bootstrap'

function ContactStrip() {
    return (
        <section className="text-center">
            <p>Let’s talk</p>
            <a href="/contact" className="btn extra-padd">Contact Us</a>
        </section>
    )
}

export default ContactStrip