import React, { useEffect, useState } from "react"
import logo from '../images/superhuman-logo.png'

import SVGLinkedIn from '../icons/Linkedin'
import SVGInstagram from '../icons/Instagram'

function Header() {
    const appConfig = window.globalConfig
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0);
    const [bannerHeight, setBannerHeight] = useState(0);

    const handleScroll = () => {
        const position = window.scrollY;
        const height = window.innerHeight;
        setScrollPosition(position);
        setBannerHeight(height - (height / 3))
    }

    useEffect(() => {
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll)

        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <>
            <header className={scrollPosition > bannerHeight ? "bg-colour" : ""}>
                <a href="/" className="logo">
                    <img src={logo} alt={appConfig.siteName} />
                </a>
                <div className={`menu ${isMenuOpen ? "menu-open" : ""}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <span className="menu-text">Menu</span>
                    <div className="menu-burger" data-menu="12" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <div className="icon"></div>
                    </div>
                </div>
            </header>
            <div id="menu" className={isMenuOpen ? "show" : ""}>
                <div className="container">
                    <nav>
                        <ul>
                            <li>
                                <a href="/">
                                    Home
                                </a>
                                <a href="/discover">
                                    Discover
                                </a>
                                <a href="/work">
                                    Work 
                                </a>
                                <a href="/contact">
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="contact">
                        <a href="https://www.linkedin.com/company/superhuman-creative/about/?viewAsMember=true" target="_blank" rel="noreferrer">
                            <SVGLinkedIn width="50" height="50" viewBox="0 0 24 24" />
                        </a>
                        <a href="https://www.instagram.com/superhumancreative/" target="_blank" rel="noreferrer">
                            <SVGInstagram width="50" height="50" viewBox="0 0 24 24" />
                        </a>
                        <div className="links">
                            <a href="mailto:hello@superhumancreative.co.uk">hello@superhumancreative.co.uk</a>
                            <a href="tel:07917625007">07917 625007</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header