import React from 'react';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

// import './css/bootstrap.min.css'

import "react-responsive-carousel/lib/styles/carousel.min.css"
import './css/App.css'

// Screens
import Home from './screens/home'
import Discover from './screens/discover'
import Work from './screens/work'
import Contact from './screens/contact'
import Amdocs from './screens/case-studies/amdocs'
import HenleyFestival from './screens/case-studies/henleyFestival'
import WWF from './screens/case-studies/wwf'
import Bridgestone from './screens/case-studies/bridgestone'
import Plink from './screens/case-studies/plink'
import Hedgehog from './screens/case-studies/hedgehog'
import ChampionsClub from './screens/case-studies/championsClub'
import AnimalHealthTrust from './screens/case-studies/animalHealthTrust'

// Global Components
import Header from './global/header'
import Footer from './global/footer'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<><Header /><Home /><Footer /></>}/>
        <Route path="/discover" element={<><Header /><Discover /><Footer /></>}/>
        <Route path="/work" element={<><Header /><Work /><Footer /></>}/>
        <Route path="/contact" element={<><Header /><Contact /><Footer /></>}/>
        <Route path="/case-studies/amdocs" element={<><Header /><Amdocs /><Footer /></>}/>
        <Route path="/case-studies/henley-festival" element={<><Header /><HenleyFestival /><Footer /></>}/>
        <Route path="/case-studies/world-wildlife-fund" element={<><Header /><WWF /><Footer /></>}/>
        <Route path="/case-studies/bridgestone-motorsport" element={<><Header /><Bridgestone /><Footer /></>}/>
        <Route path="/case-studies/paddock-link" element={<><Header /><Plink /><Footer /></>}/>
        <Route path="/case-studies/hedgehog-insurance" element={<><Header /><Hedgehog /><Footer /></>}/>
        <Route path="/case-studies/champions-club" element={<><Header /><ChampionsClub /><Footer /></>}/>
        <Route path="/case-studies/animal-health-trust" element={<><Header /><AnimalHealthTrust /><Footer /></>}/>
      </Routes>
    </Router>
  );
}

export default App;
