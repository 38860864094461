import React from "react"
import { Helmet } from 'react-helmet'
import AnimateElemement from '../global/animateElement'

// Components
import BannerArrow from '../global/bannerArrow'
import ContactStrip from '../global/contactStrip'
import Testimonials from '../global/testimonials'

function Work() {
    const appConfig = window.globalConfig

    const testimonials = [
        {
            content: <>
                SuperHuman have worked for a number of years
                for Bridgestone Motorsport, both in Formula One
                and MotoGP, building a solid and trusting
                relationship along the way.
            </>,
            cite: <>Press Officer - Bridgestone Motorsport</>
        },
        {
            content: <>
                The team at SuperHuman surpassed my expectations, 
                delivering exceptional creative, on time and on budget.<br />
                Would highly recommend!
            </>,
            cite: <>CEO - Hedgehog Insurance</>
        },
        {
            content: <>
                SuperHuman provided a first class service and looked 
                holistically at all of our needs as a charity brand 
                struggling to stand out from the crowd. I wouldn’t 
                hesitate to recommend to others.
            </>,
            cite: <>Director of Fundraising and Marketing - Animal Health Trust</>
        }
    ]

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Work</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">

                <div class="banner full work">
                    <AnimateElemement animation="fade" className="text-center">
                        <h1>Work</h1>
                    </AnimateElemement>
                    <BannerArrow />
                </div>

                <section className="bg chartreuse">
                    <div className="container">
                        <p className="fs-4">
                            We’re proud to be an extension of our clients’
                            business. We work with some of the biggest global
                            brands providing fresh insights and amazing design
                            scalable to your needs.
                        </p>
                        <p className="fs-4">
                            Discover some of our latest projects below.
                        </p>
                    </div>
                </section>

                <section className="bg equidistant">
                    <div className="container">
                        <h2 className="text-center marg-b-0">Case Studies</h2>
                    </div>
                </section>

                <section className="cta-links">
                    <a href="/case-studies/amdocs" className="amdocs">
                        <span>Amdocs</span>
                    </a>
                    <a href="/case-studies/henley-festival" className="henley-festival">
                        <span>Henley Festival</span>
                    </a>
                    <a href="/case-studies/world-wildlife-fund" className="wwf">
                        <span>World Wildlife Fund</span>
                    </a>
                    <a href="/case-studies/bridgestone-motorsport" className="bridgestone">
                        <span>Bridgestone Motorsport</span>
                    </a>
                    <a href="/case-studies/paddock-link" className="plink">
                        <span>Paddock Link</span>
                    </a>
                    <a href="/case-studies/hedgehog-insurance" className="hedgehog">
                        <span>Hedgehog Insurance</span>
                    </a>
                    <a href="/case-studies/champions-club" className="champions">
                        <span>Champions Club</span>
                    </a>
                    <a href="/case-studies/animal-health-trust" className="aht">
                        <span>Animal Health Trust</span>
                    </a>
                </section>

                <Testimonials data={testimonials} />

                <ContactStrip />
            </div>
        </>
    )
}

export default Work