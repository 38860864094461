import React, { useEffect, useRef, useState } from "react"
import { Helmet } from 'react-helmet'

// Components
import BannerArrow from '../../global/bannerArrow'
import ContactStrip from '../../global/contactStrip'
import Testimonials from '../../global/testimonials'

// images
import logo from '../../images/case-studies/champions-club/CC-logo.png'
import books from '../../images/case-studies/champions-club/champions-club-books.jpg'
import bookSpread from '../../images/case-studies/champions-club/will-spread.jpg'
import screenshot from '../../images/case-studies/champions-club/Screen-shot.jpg'
import bookSpread2 from '../../images/case-studies/champions-club/will-spread-2.jpg'
import laptop from '../../images/case-studies/champions-club/will-web.jpg'
import boats from '../../images/case-studies/champions-club/A8C9093.jpg'
import thumbChampion from '../../images/case-studies/champions-club/champion.jpg'
import thumbBookText from '../../images/case-studies/champions-club/book-text.jpg'
import thumbBookZoom from '../../images/case-studies/champions-club/champions-club-book-zoom.jpg'
import thumbBooks from '../../images/case-studies/champions-club/books.jpg'
import videoURL from '../../images/case-studies/champions-club/Will-Stevens-Clip.mp4'

function ChampionsClub() {
    const appConfig = window.globalConfig
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        if (!isPlaying) {
            videoRef.current.play()
            setIsPlaying(true)
        }
        else {
            videoRef.current.pause()
            setIsPlaying(false)
        }
    };

    const handlePlay = () => {
        setIsPlaying(true)
    }

    const testimonials = [
        {
            content: <>
                We haven’t looked back since we started
                working with SuperHuman. They have always
                over delivered and always bring that extra
                sparkle that makes all the difference.
            </>,
            cite: <>Director - ADD Management</>
        }
    ]

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Case Studies - Champions Club</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">

                <div class="banner full champions-club">
                    <img src={logo} alt="Champions Club Logo" />
                    <BannerArrow />
                </div>

                <section>
                    <div className="container">
                        <p className="fs-2-5 width-p-80 block-center">
                            It’s difficult to find a diamond in the rough, and in the world of
                            motorsport how do you find the best talent? The Champions Club
                            was born – an advocacy programme with a difference. We helped
                            to raise the profiles of the latest motorsport talent (such as Will
                            Stevens) to investors and industry professionals.
                        </p>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={books} className="width-p-100" alt="The Champions Club - Books" />
                </section>

                <section className="padd-0">
                    <img src={bookSpread} className="width-p-100" alt="The Champions Club - Champion" />
                </section>

                <section className="padd-0">
                    <img src={screenshot} className="width-p-100" alt="The Champions Club" />
                </section>

                <section>
                    <div className="video-player-container">
                        <video
                            className="video-player"
                            ref={videoRef}
                            controls={isPlaying}
                            preload="metadata"
                            onClick={togglePlay}
                            onPlay={handlePlay}

                        >
                            <source src={videoURL} type="video/mp4" />
                        </video>
                        {!isPlaying && (
                            <div className="play-overlay" onClick={togglePlay}>
                                <div className="icon"></div>
                            </div>
                        )}
                    </div>
                </section>

                <section className="bg black">
                    <div className="container">
                        <h2>We delivered</h2>
                        <div className="grid cols3">
                            <div><p className="fs-2 width-p-70">A platform to showcase the latest new talent</p></div>
                            <div><p className="fs-2 width-p-80">A public profile and identity for future champions</p></div>
                            <div><p className="fs-2 width-p-90">A creation of dedicated member websites</p></div>
                        </div>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={bookSpread2} className="width-p-100" alt="The Champions Club" />
                </section>

                <section className="section-text-image">
                    <div className="row">
                        <div className="image bg grey">
                            <img src={laptop} className="width-p-100" alt="The Champions Club - Will Stevens" />
                        </div>
                        <div className="text bg black">
                            <p className="fs-2-5 padd-b-50 width-p-60 block-center">
                                We enabled the revolution of
                                the talent acquisition
                                experience, helping new drivers
                                get noticed for investment and
                                build momentum in their careers.
                            </p>

                            <p className="fs-2-5 marg-0 width-p-60 block-center">
                                Will Stevens has had a very
                                successful racing career since
                                in Formula One and many other
                                top tier categories.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="padd-0">
                    <img src={boats} className="width-p-100" alt="The Champions Club Race" />
                </section>

                <Testimonials data={testimonials} />

                <section>
                    <div className="container">
                        <div className="gallery cols2">
                            <div><img src={thumbChampion} alt="Champion Meaning" /></div>
                            <div><img src={thumbBookText} alt="Champion Text" /></div>
                            <div><img src={thumbBookZoom} alt="The Champions Club book cover" /></div>
                            <div><img src={thumbBooks} alt="The Champions Club books" /></div>
                        </div>
                    </div>
                </section>

                <section className="prev-next-project">
                    <div className="row">
                        <a href="/case-studies/hedgehog-insurance" className="item prev hedgehog">
                            <span class="chevron left"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Previous Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Hedgehog Insurance</strong></p>
                            </div>
                        </a>
                        <a href="/case-studies/animal-health-trust" className="item next animal-health-trust">
                            <span class="chevron right"></span>
                            <div className="content">
                                <p className="text-green fs-1 marg-b-0">Next Project</p>
                                <p className="fs-1-2 marg-t-10"><strong>Animal Health Trust</strong></p>
                            </div>
                        </a>
                    </div>
                </section>

                <ContactStrip />
            </div>
        </>
    )
}

export default ChampionsClub