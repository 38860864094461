import React, { useEffect, useRef, useState } from "react"
import { Helmet } from 'react-helmet'

// Components
import BannerArrow from '../global/bannerArrow'
import ContactStrip from '../global/contactStrip'
import Testimonials from '../global/testimonials'
import AnimateElemement from '../global/animateElement'

// Images and Videos
import videoURL from '../videos/home-video.mp4'
import logo from '../images/superhuman-logo-large.png'
import amdocsLogo from '../images/client-logos/amdocs-logo.png'
import henleyFestivalLogo from '../images/client-logos/hf-logo.png'
import wwfLogo from '../images/client-logos/WWF-logo-homepage.png'
import hondaLogo from '../images/client-logos/honda-logo.png'
import samsungLogo from '../images/client-logos/samsung-logo.png'
import bridgestoneMotorsportLogo from '../images/client-logos/Bridgestone_MotorsportLogoWO.png'
import pLinkLogo from '../images/client-logos/Plink.png'
import hedgehogLogo from '../images/client-logos/hedgehog_logo_white.png'

function Home() {
    const appConfig = window.globalConfig
    let videoRef = useRef(0)
    
    const testimonials = [
        {
            content: <>
                SuperHuman have worked for a number of years
                for Bridgestone Motorsport, both in Formula One
                and MotoGP, building a solid and trusting
                relationship along the way.
            </>,
            cite: <>Press Officer - Bridgestone Motorsport</>
        },
        {
            content: <>
                The team at SuperHuman surpassed my expectations, 
                delivering exceptional creative, on time and on budget.<br />
                Would highly recommend!
            </>,
            cite: <>CEO - Hedgehog Insurance</>
        },
        {
            content: <>
                SuperHuman provided a first class service and looked 
                holistically at all of our needs as a charity brand 
                struggling to stand out from the crowd. I wouldn’t 
                hesitate to recommend to others.
            </>,
            cite: <>Director of Fundraising and Marketing - Animal Health Trust</>
        }
    ]

    useEffect(() => {
        // videoRef.current.play()
    }, [])

    

    return (
        <>
            <Helmet>
                <title>{appConfig.siteName} - Home</title>
                <meta name="description" content="This is my page description." />
            </Helmet>
            <div className="page">
                <div className="video-banner home">
                    <div className="video">
                        <video ref={videoRef} playsInline autoPlay muted loop>
                            <source src={videoURL} type="video/mp4" />
                        </video>
                    </div>
                    <div className="video-content">
                        <p className="fs-4">Welcome to</p>
                        <img src={logo} alt={appConfig.siteName} />
                        <p className="tt-upper">Creative</p>
                    </div>
                    <BannerArrow />
                </div>

                <section className="bg green">
                    <div className="container">
                        <AnimateElemement animation="fade" className="fs-3-5">
                            <p>
                                SuperHuman is a proudly independent,
                                full-service creative agency. 
                            </p>
                        </AnimateElemement>
                        <AnimateElemement animation="fade" className="fs-3-5">
                            <p>
                                We are dedicated to showcasing and
                                celebrating the remarkable achievements of
                                individuals from all walks of life, embodying a
                                spirit of inclusivity and empowerment through
                                design and marketing campaigns.
                            </p>
                        </AnimateElemement>
                    </div>
                </section>

                <section className="main-ctas">
                    <h3>View latest projects</h3>
                    <a href="/case-studies/amdocs" className="amdocs">
                        <span>Amdocs</span>
                    </a>
                    <a href="/case-studies/henley-festival" className="henley-festival">
                        <span>Henley Festival</span>
                    </a>
                    <a href="/case-studies/world-wildlife-fund" className="wwf">
                        <span>World Wildlife Fund</span>
                    </a>
                    <a href="/case-studies/bridgestone-motorsport" className="bridgestone">
                        <span>Bridgestone Motorsport</span>
                    </a>
                    <a href="/case-studies/paddock-link" className="paddock-link">
                        <span>Paddock Link</span>
                    </a>
                    <a href="/case-studies/hedgehog-insurance" className="hedgehog">
                        <span>Hedgehog Insurance</span>
                    </a>
                    <a href="/case-studies/champions-club" className="champions-club">
                        <span>Champions Club</span>
                    </a>
                    <a href="/case-studies/animal-health-trust" className="animal-health">
                        <span>Animal Health Trust</span>
                    </a>
                </section>

                <section className="bg green-dark">
                    <div className="container">
                        <h2 className="text-center text-chartreuse">What we do</h2>
                        <p className="fs-4-9 text-chartreuse">
                            Brand concepts <span className="text-green">Campaign strategies</span> Story-telling <span className="text-green">Web design</span> Graphic design&nbsp;
                            <span className="text-green">Video storyboarding</span> Internal comms <span className="text-green">Social media</span> Print & digital <span className="text-green">Events</span>
                        </p>
                    </div>
                </section>

                <section className="bg purple">
                    <div className="container">
                        <h2 className="text-center">A few of our clients</h2>
                        <ul className="client-logos">
                            <AnimateElemement animation="fade">
                                <li>
                                    <img src={amdocsLogo} alt="amdocs" />
                                </li>
                            </AnimateElemement>
                            <AnimateElemement animation="fade">
                                <li>
                                    <img src={henleyFestivalLogo} alt="Henley Festival" />
                                </li>
                            </AnimateElemement>
                            <AnimateElemement animation="fade">
                                <li>
                                    <img src={wwfLogo} alt="World Wildlife Fund" />
                                </li>
                            </AnimateElemement>
                            <AnimateElemement animation="fade">
                                <li>
                                    <img src={hondaLogo} alt="Honda" />
                                </li>
                            </AnimateElemement>
                            <AnimateElemement animation="fade">
                                <li>
                                    <img src={bridgestoneMotorsportLogo} alt="Bridgestone Motorsport" />
                                </li>
                            </AnimateElemement>
                            <AnimateElemement animation="fade">
                                <li>
                                    <img src={pLinkLogo} alt="Paddock Link" />
                                </li>
                            </AnimateElemement>
                            <AnimateElemement animation="fade">
                                <li>
                                    <img src={hedgehogLogo} alt="Hedgehog Insurance" />
                                </li>
                            </AnimateElemement>
                            <AnimateElemement animation="fade">
                                <li>
                                    <img src={samsungLogo} alt="Samsung" />
                                </li>
                            </AnimateElemement>
                        </ul>
                    </div>
                </section>

                <Testimonials data={testimonials} />

                <ContactStrip />

            </div>
        </>
    )
}

export default Home